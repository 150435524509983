<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
    <div class="contacts-mobile-title container  d-md-none">{{ $t('contacts') }}</div>
    <div id="main-container" class="">
        <div class="container ">
            <div class="d-md-flex"> <div class="col-md-7   contacts-left-wrapper">

              <div class="">
                <div class="">
                  <div class="need-help-title"> {{ $t('do-you-need-help') }}</div>
                  <div class="need-help-subtitle"> {{ $t('send-inquiry-or-call') }}</div>
                </div>
                <div class="contact-title-item">
                  <div class=""> Email:</div>
                  <div class=""> {{ contact_details.email }}</div>
                </div>
                <div class="contact-title-item no-border">
                  <div class=""> {{ $t('location') }}:</div>
                  <div class=""> {{ contact_details.location }}</div>
                </div>
              </div>
            </div>
              <div class="col-md-7  contacts-right-wrapper" v-if="form">
                <div class="contacts-right-title d-none d-md-block">{{ $t('contacts') }}</div>
                <div class="row">
                  <div class="col-md-4 input-items">
                    <InputField v-model="form.name" :content="form.name" :inputtype="'text'"
                                :label="$t('your-name')" :error="formErrors.includes('name')"/>
                  </div>
                  <div class="col-md-4 input-items">
                    <InputField v-model="form.phone" :content="form.phone" :inputtype="'text'"
                                :label="$t('your-number')" :error="formErrors.includes('phone')"/>
                  </div>
                  <div class="col-md-4 input-items">
                    <InputField v-model="form.email" :content="form.email" :inputtype="'text'" :label="$t('email')"
                                :error="formErrors.includes('email')"/>
                  </div>
                </div>
                <div class="row input-items text-area">
              <TextArea v-model="form.message" :content="form.message" :label="$t('your-message')"
                        :error="formErrors.includes('message')"/>

                </div>
                <div class="col-md-5">
                  <Button :text="$t('send')" :size="'medium'" :theme="'solid-orange'" @click="sendMessage"/>
                </div>
              </div></div>

        </div>

    </div>
</template>

